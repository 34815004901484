import React, { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

const pages1_icon = [<HomeIcon sx={{ color: '#05253C' }}/>, <InfoIcon  sx={{ color: '#05253C' }}/>,
    <HomeRepairServiceIcon  sx={{ color: '#05253C' }}/>, <AddIcCallIcon  sx={{ color: '#05253C' }}/>];

const list = (anchor, setMenuStatus, pages1, link) => (
    <Box
        sx={{ width:  250 }}
        role="presentation"
    >
        <List sx={{ color: '#05253C' }}>
            {pages1.map((page, index) => (
                <ListItem key={page} disablePadding>
                    <ListItemButton onClick={() => {  setMenuStatus(false); window.location.replace(`${link}${page.link}`); }}>
                        <ListItemIcon>
                            {pages1_icon[index]}
                        </ListItemIcon>
                        <ListItemText primary={page.title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Box>
);

const Drawer = ({ menuStatus, setMenuStatus, pages1, link }) => {
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') ) {
            return;
        }
        setMenuStatus(open);
    };
    return (
        <div>
            <SwipeableDrawer
                anchor={'left'}
                open={menuStatus}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list('left', setMenuStatus, pages1, link)}
            </SwipeableDrawer>
        </div>
    )
}

export default Drawer;