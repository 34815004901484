import * as React from 'react';
import Grid from '@mui/material/Grid';
import BrushIcon from '@mui/icons-material/Brush';
import WebIcon from '@mui/icons-material/Web';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SecurityIcon from '@mui/icons-material/Security';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import Container from "@mui/material/Container";
import "./Services.css";
import {useMediaQuery} from "react-responsive";

export default function Services() {
    const serviceIconSizeBreak1 = useMediaQuery({ query: '(min-width: 550px)' });
    const serviceIconSizeBreak2 = useMediaQuery({ query: '(min-width: 400px)' });

    const serviceIconTextSizeBreak1 = useMediaQuery({ query: '(min-width: 600px)' });
    const serviceIconTextSizeBreak2 = useMediaQuery({ query: '(min-width: 500px)' });
    const serviceIconTextSizeBreak3 = useMediaQuery({ query: '(min-width: 450px)' });
    
    const getServiceIconSize = () => {
        var serviceIconClassName = "services_icon ";
        if(serviceIconSizeBreak1) {
            serviceIconClassName += "services_icon1";
        }
        else if(serviceIconSizeBreak2) {
            serviceIconClassName += "services_icon2";
        }
        else {
            serviceIconClassName += "services_icon3";
        }
        return serviceIconClassName;
    }
    
    const getServiceTextSize = () => {
        var serviceTextClassName = "services_icon_text ";
        if(serviceIconTextSizeBreak1) {
            serviceTextClassName += "services_icon_text1";
        }
        else if(serviceIconTextSizeBreak2) {
            serviceTextClassName += "services_icon_text2";
        }
        else if(serviceIconTextSizeBreak3) {
            serviceTextClassName += "services_icon_text3";
        }
        else {
            serviceTextClassName += "services_icon_text4";
        }
        return serviceTextClassName;
    }

    const getServiceGridItem = () => {
        var serviceGridItemClassName = "services_grid_item ";
        if(serviceIconTextSizeBreak1) {
            serviceGridItemClassName += "services_grid_item1";
        }
        else if(serviceIconTextSizeBreak2) {
            serviceGridItemClassName += "services_grid_item2";
        }
        else if(serviceIconTextSizeBreak3) {
            serviceGridItemClassName += "services_grid_item3";
        }
        else {
            serviceGridItemClassName += "services_icon_text4";
        }
        return serviceGridItemClassName;
    }
    
    return (
        <div id="services">
            <Container className="services_container">
                <Grid container spacing={5}>
                    <Grid item xs={4} md={4} className={getServiceGridItem()}>
                        <div className="service_item_div">
                            <BrushIcon className={getServiceIconSize()}/>
                            <div className={getServiceTextSize()}>Web Design</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={4} className={getServiceGridItem()}>
                        <div className="service_item_div">
                            <WebIcon className={getServiceIconSize()}/>
                            <div className={getServiceTextSize()}>Web Development</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={4} className={getServiceGridItem()}>
                        <div className="service_item_div">
                            <StorefrontIcon className={getServiceIconSize()}/>
                            <div className={getServiceTextSize()}>E-commerce Solutions</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={4} className={getServiceGridItem()}>
                        <div className="service_item_div">
                            <SecurityIcon className={getServiceIconSize()}/>
                            <div className={getServiceTextSize()}>Cybersecurity Solutions</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={4} className={getServiceGridItem()}>
                        <div className="service_item_div">
                            <DashboardIcon className={getServiceIconSize()}/>
                            <div className={getServiceTextSize()}>Dashboard Development</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={4} className={getServiceGridItem()}>
                        <div className="service_item_div">
                            <ContentPasteSearchIcon className={getServiceIconSize()}/>
                            <div className={getServiceTextSize()}>SEO Optimization</div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}