import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
        <Navbar />
        <Home />
        <About />
        <Services />
        <ContactUs />
        <br /><br /><br />
        <Footer />
    </div>
  );
}

export default App;
