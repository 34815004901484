import React from 'react';
import Container from "@mui/material/Container";
import Image from '../../assets/landing_page_bg.jpeg';
import { useMediaQuery } from 'react-responsive';
import "./Home.css";

const Home = () => {
    const posterSizeBreak1 = useMediaQuery({ query: '(min-width: 750px)' });
    const posterSizeBreak2 = useMediaQuery({ query: '(min-width: 550px)' });
    const posterSizeBreak3 = useMediaQuery({ query: '(min-width: 450px)' });

    const getPosterHeight = () => {
        if(posterSizeBreak1) {
            return "90vh";
        }
        else if(posterSizeBreak2) {
            return "70vh";
        }
        else if(posterSizeBreak3) {
            return "50vh";
        }
        return "40vh";
    }

    const getPosterTextId = () => {
        if(posterSizeBreak1) {
            return "poster_text1";
        }
        else if(posterSizeBreak2) {
            return "poster_text2";
        }
        else if(posterSizeBreak3) {
            return "poster_text3";
        }
        return "poster_text4";
    }

    return (
        <div id="home" style={{ height: getPosterHeight(), backgroundColor: '#FFE8D4' }}>
            <div className="poster" style={{ backgroundImage: `url(${Image})`, height: '100%',
                backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <Container sx={{ height: '100%', display: 'flex',
                    justifyContent: 'center', alignItems: 'center' }}>
                    <div id={getPosterTextId()} className="poster_text">
                        We help businesses build their brands!
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Home;