import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import "./Footer.css";

const Footer = () => {
    return (
        <div id="footer">
            <div>
                <div style={{ display: 'flex', alignItems: 'center', height: '30px' }}>
                    <PhoneIcon />
                    <h3 style={{ marginLeft: '10px' }}>+91-8625849332</h3>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: '30px' }}>
                    <EmailIcon />
                    <h3 style={{ marginLeft: '10px' }}>support@netbyteforge.in</h3>
                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <FacebookIcon />
                    <InstagramIcon />
                    <a href="" className="icon_link" target="_blank">
                        <LinkedInIcon/>
                    </a>
                    <XIcon />
                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    Copyright © NetByteForge. All rights reserved
                </div>
            </div>
        </div>
    )
}
export default Footer;