import React from 'react';
import Grid from '@mui/material/Grid';
import ContactForm from "./ContactForm/ContactForm";
import BusinessIcon from '@mui/icons-material/Business';
import HelpIcon from '@mui/icons-material/Help';
import ComputerIcon from '@mui/icons-material/Computer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Container from "@mui/material/Container";
import "./ContactUs.css";

const ContactUs = () => {
    return (
        <div id="contact">
            <Container className="contact_container">
                <h1 className="contact_heading">Let's start a conversation</h1>
                <Grid container>
                    <Grid item xs={12} sm={6} className="contact_point_text_section" sx={{ borderRadius: '20px 0 0 20px' }}>
                        <h2 style={{ fontFamily: 'Salsa, serif', color: '#05253C' }}>If you....</h2>
                        <div className="contact_point">
                            <BusinessIcon className="contact_point_icon" />
                            <div className="contact_point_text">Want to unlock your business potential</div>
                        </div>
                        <div className="contact_point">
                            <HelpIcon className="contact_point_icon" />
                            <div className="contact_point_text">Seek strategic advice for your digital presence</div>
                        </div>
                        <div className="contact_point">
                            <ComputerIcon className="contact_point_icon" />
                            <div className="contact_point_text">Require technical support</div>
                        </div>
                        <div className="contact_point">
                            <EmojiEventsIcon className="contact_point_icon" />
                            <div className="contact_point_text">Are considering a brand new project</div>
                        </div>
                        <div className="contact_point">
                            <FeedbackIcon className="contact_point_icon" />
                            <div className="contact_point_text">Have feedback or suggestions</div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContactForm />
                    </Grid>
                </Grid>

            </Container>
        </div>
    )
}

export default ContactUs;