import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import './ContactForm.css'

const options = {
    shouldForwardProp: (prop) => prop !== 'borderColor' || prop !== 'fontColor',
};

const outlinedSelectors = [
    '& .MuiOutlinedInput-notchedOutline',
    '&:hover .MuiOutlinedInput-notchedOutline',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline',
];

const TextFieldMine = styled(
    TextField,
    options,
)(({ borderColor, fontColor }) => ({
    '& label.Mui-focused': {
        color: borderColor,
    },
    input: {
        color: fontColor,
    },
    [outlinedSelectors.join(',')]: {
        borderWidth: 3,
        borderColor,
        fontColor,
    },
}));

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const handleSubmitButton = async() => {
        setName("");
        setEmail("");
        setContent("");
        alert("Message sent");
    };

    return (
        <div style={{ padding: '0 20px', backgroundColor: '#05253C',
            height: '100%', fontFamily: 'Salsa, serif', borderRadius: '0 20px 20px 0' }}>
            <div style={{ paddingTop: '20px' }}>
                <TextFieldMine id="outlined-multiline-flexible"
                           variant="outlined" borderColor="white" focused
                           inputProps={{ style: { color: "white" } }} defaultValue={""}
                           label="Your message"
                           multiline rows={10} fullWidth
                           onChange={(event) => {
                               setContent(event.target.value);
                           }} value={content}/>
            </div>
            <div>
                <TextFieldMine id="outlined-multiline-flexible" label="Your name"
                           variant="outlined" borderColor="white" focused
                           inputProps={{ style: { color: "white" } }}
                           fullWidth multiline defaultValue=""
                           onChange={(event) => {
                               setName(event.target.value);
                           }} value={name}
                           style={{ marginTop: '20px' }}/>
                <TextFieldMine id="outlined-multiline-flexible" label="Your email"
                           variant="outlined" borderColor="white" focused
                           inputProps={{ style: { color: "white" } }}
                           fullWidth multiline defaultValue=""
                           onChange={(event) => {
                               setEmail(event.target.value);
                           }} value={email}
                           style={{ marginTop: '20px' }}/>
            </div>

            <div style={{ padding: '25px 0' }}>
                <button id="contact_form_submit" onClick={() => handleSubmitButton()}>
                    Submit
                </button>
            </div>
        </div>
    )
}

export default ContactForm;