import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useMediaQuery } from 'react-responsive';
import Logo from '../../assets/logo.png';
import Aos from "aos";
import "aos/dist/aos.css";
import Drawer from './Drawer/Drawer';
import "./Navbar.css";

const pagesRight = [
    {
        title: 'Home',
        link: '#home',
    },
    {
        title: 'About',
        link: '#about',
    },
    {
        title: 'Services',
        link: '#services',
    },
    {
        title: 'Contact',
        link: '#contact',
    },
];

//TODO: link = "https://kitty-creme1.netlify.app";
const link=""

const Navbar = () => {
    Aos.init({ duration: 1000 });
    const [menuStatus, setMenuStatus] = useState(false);

    const showName = useMediaQuery({ query: '(min-width: 370px)' });

    const handleOpenNavMenu = (event) => {
        setMenuStatus(true);
    };

    return (
        <AppBar position="static" id="navbar">
            <Container maxWidth={false}>
                <Toolbar disableGutters sx={{ height: '10vh' }}>
                    {/* Navbar for small screen */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer menuStatus={menuStatus} setMenuStatus={setMenuStatus} pages1={pagesRight} link={link}/>
                    </Box>

                    {/* Logo for both */}
                    <div data-aos="fade-up">
                        {/* <IcecreamIcon sx={{ display: 'flex', mr: 1 }} /> */}
                        <img src={Logo} style={{ height: '5vh' }}/>
                    </div>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: 'flex',
                            flexGrow: 1,
                            fontWeight: 700,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            fontFamily: 'Salsa'
                        }}
                        data-aos="fade-up"
                    >
                        {
                            showName?
                                <span><a href={`${link}`} style={{ color: 'white', textDecoration: 'none' }}> NetByteForge</a></span>:
                                <span></span>
                        }
                    </Typography>

                    <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        {pagesRight.map((page) => (
                            <Button
                                key={page.title}
                                onClick={() => window.location.replace(`${link}${page.link}`)}
                                style={{ color: 'white', fontFamily: 'Mukta, serif' }}
                            >
                                <h3>{page.title}</h3>
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar;