import React from 'react';
import Container from "@mui/material/Container";
import { useMediaQuery } from 'react-responsive';

const About = () => {
    const aboutTopPadding1 = useMediaQuery({ query: '(min-width: 450px)' });
    const aboutTitleSize = useMediaQuery({ query: '(min-width: 550px)' });

    const getAboutSectionTopPadding = () => {
        if(aboutTopPadding1) {
            return "10vh";
        }
        return "5vh";
    }

    const getAboutTitleSize = () => {
        if(aboutTitleSize) {
            return "2.5em";
        }
        return "2.0em";
    }

    return (
        <div id="about" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
            backgroundColor: '#05253C', paddingTop: getAboutSectionTopPadding() }}>
            <Container sx={{ fontFamily: 'Salsa', textAlign: 'center' }}>
                <h1 style={{ color: '#65E4C5', fontSize: getAboutTitleSize() }} className="about_title">Unlock Your Business Potential with Stunning services.</h1>
                <h3 style={{ fontFamily: 'Mukta', color: 'white' }}>Our mission is to empower businesses and individuals by delivering quality services.</h3>
                <h3 style={{ fontFamily: 'Mukta', color: 'white' }}>We have experience catering to both individuals and businesses, providing a
                    diverse range of services. Our expertise spans from crafting static websites
                    to developing expansive dashboards designed for optimal effectiveness in various
                domains.</h3>
            </Container>
        </div>
    )
}

export default About;